.Type1 {
  scroll-behavior: smooth;
}
.type1-Header {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  &.scroll {
    height: 70px;
    background-color: #130303cf;
    img {
      height: 60px;
    }
  }
}
.type1-headerBox1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  font-family: "Montserrat-Bold";
  color: #fff;
  font-size: 14px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    object-fit: contain;
  }
}
.type1-headerBox2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    width: 220px;
    height: 90px;
    object-fit: contain;
  }
}
.type1-headerBox3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.type1-headerButton {
  font-family: "Montserrat-SemiBold";
  border: 0px;
  background-color: #fff;
  border-radius: 100px;
  padding: 10px 30px;
  font-size: 14px;
}

.type1-Block1 {
  color: #fff;
  border-top: 3px solid #fff;
  height: 100vh;
  width: 100%;
  margin: 0px;
  top: 0px;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.type1-Block1overlay {
  color: #fff;
  background-image: linear-gradient(#121212ce, #7878783d);
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  border-top: 3px solid #fff;
}
.type1-box1 {
  border-right: 3px solid #fff;
  height: 100vh;
  padding-top: 90px;
  display: flex;
  align-items: flex-end;
  padding: 30px;
  padding-bottom: 100px;
}
.type1-box2 {
  border-right: 3px solid #fff;
  height: 100vh;
  padding-top: 90px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
}
.type1-box3 {
  height: 100vh;
  padding-top: 90px;
  padding: 30px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 100px;
}
.type1-txt1 {
  color: #fff;
  font-size: 40px;
  font-family: "Montserrat-Medium";
  span {
    font-family: "Montserrat-Bold";
    font-size: 50px;
  }
}
.type1-txt2 {
  color: #fff;
  font-family: "Montserrat-Bold";
  font-size: 25px;
  &:hover {
    color: red;
  }
}
.type1-txt3 {
  color: #fff;
  font-family: "Montserrat-SemiBold";
  font-size: 23px;
}
.type1-Block2 {
  margin-top: 100px;
  margin-bottom: 50px;
}
.type1-txt4 {
  font-family: "Montserrat-Bold";
  text-align: left;
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
  font-size: 50px;
}
.type1-txt5 {
  text-align: left;
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  line-height: 30px;
}
.type1-Block3 {
  background: linear-gradient(-45deg, #d4c5c3, #e8e8e8);
  animation: gradient 15s ease infinite;
  padding-top: 50px;
  padding-bottom: 100px;
}
.type1-Block3Box2row {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.line {
    border-bottom: 3px solid #6d6d6d;
  }
}
.type1-Block3Box3 {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.type1-Block3Box3Image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.type1-line {
  background-color: red;
  width: 20%;
  height: 3px;
}
.type1-Block2box1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.type1-Block4 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.type1-Block4Item {
  display: flex;
  flex-direction: row;
  border-top: 2px solid rgb(218, 218, 218);
  border-left: 2px solid rgb(218, 218, 218);
  margin-bottom: 30px;
}
.type1-Block4Img {
  width: 150px;
  height: 150px;
  border: 2px solid rgb(218, 218, 218);
}
.type1-Block4Item2 {
  margin: 10px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.type1-Block4txt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 25px;
  color: #000;
  text-align: right;
}
.type1-Block4txt2 {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #ff0000;
  text-align: right;
}
.type1-Footer {
  background-color: #272727;
  padding-top: 50px;
}
.type1-Footertxt1 {
  color: #fff;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 10px;
  font-size: 20px;
}
.type1-Footertxt2 {
  color: #fff;
  font-family: "Montserrat-Medium";
  margin-bottom: 20px;
  font-size: 14px;
}
.type1-Footertxt3 {
  text-align: center;
  color: #fff;
  font-family: "Montserrat-Regular";
  font-size: 12px;
  border-top: 1px solid #464646;
  padding: 30px;
}
.type1-FooterBox1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  font-size: 30px;
}
