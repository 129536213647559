.Type2-Header {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  &.scroll {
    height: 70px;
    background-color: #ba56568f;
  }
}

.Type2-HeaderBox1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  padding-left: 50px;
  img {
    width: 240px;
    height: 120px;
    object-fit: contain;
  }
}
.Type2-HeaderBox2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
  padding-right: 50px;
}
.Type2-HeaderBox2btn {
  border: 1px solid rgb(255, 255, 255);
  padding: 15px 30px;
  border-radius: 100px;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  padding-bottom: 8px;
  color: #fff;
  height: 50px;
}
.Type2-HeaderBox2btn2 {
  border: 1px solid rgb(255, 255, 255);
  margin-right: 20px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: #fff;
}
.Type2-HeaderBox3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.type2-Block1 {
  color: #fff;
  height: 100vh;
  width: 100%;
  margin: 0px;
  top: 0px;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.type2-Block1overlay {
  background-image: linear-gradient(#5131315f, #383232af, #4d2a2a00);
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-top: 100px;
}
.type2-Block1Box1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80vh;
  margin-left: 50px;
}
.type2-Block1txt1 {
  font-family: "Montserrat-SemiBold";
  font-size: 40px;
}
.type2-Block1txt2 {
  font-family: "Montserrat-Bold";
  font-size: 100px;
}
.type2-Block1Box2 {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  align-items: flex-end;
  padding-right: 10px;
  height: 100%;
}
.type2-Block1txt3 {
  margin-bottom: 10px;
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  color: #fff;
}
.type2-Block1Box2btn1 {
  border: 1px solid rgb(255, 255, 255);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-radius: 100px;
}
